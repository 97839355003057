import request from '@/api/request.js'

/**
 * @Description: 商品
 */

// 分页查询
export function pageWmsSku(data) {
    return request({
        url: '/api/wmsSku/page',
        method: 'POST',
        data
    })
}

// 搜索过滤
export function listWmsSku(data) {
    return request({
        url: '/api/wmsSku/list',
        method: 'POST',
        data
    })
}

// 查看详情
export function detailWmsSku(data) {
    return request({
        url: '/api/wmsSku/detail',
        method: 'POST',
        data
    })
}

// 添加
export function addWmsSku(data) {
    return request({
        url: '/api/wmsSku/add',
        method: 'POST',
        data
    })
}

// 编辑
export function editWmsSku(data) {
    return request({
        url: '/api/wmsSku/edit',
        method: 'POST',
        data
    })
}

// 删除
export function delWmsSku(data) {
    return request({
        url: '/api/wmsSku/delete',
        method: 'POST',
        data
    })
}

// 批量删除
export function batchDeleteWmsSku(data) {
    return request({
        url: '/api/wmsSku/batchDelete',
        method: 'POST',
        data
    })
}

