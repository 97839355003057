import request from '@/api/request.js'

/**
 * @Description: 库位
 */

// 分页查询
export function pageWmsBinLocation(data) {
    return request({
        url: '/api/wmsBinLocation/page',
        method: 'POST',
        data
    })
}

// 搜索过滤
export function listWmsBinLocation(data) {
    return request({
        url: '/api/wmsBinLocation/list',
        method: 'POST',
        data
    })
}

// 查看详情
export function detailWmsBinLocation(data) {
    return request({
        url: '/api/wmsBinLocation/detail',
        method: 'POST',
        data
    })
}

// 添加
export function addWmsBinLocation(data) {
    return request({
        url: '/api/wmsBinLocation/add',
        method: 'POST',
        data
    })
}

// 编辑
export function editWmsBinLocation(data) {
    return request({
        url: '/api/wmsBinLocation/edit',
        method: 'POST',
        data
    })
}

// 删除
export function delWmsBinLocation(data) {
    return request({
        url: '/api/wmsBinLocation/delete',
        method: 'POST',
        data
    })
}

// 批量删除
export function batchDeleteWmsBinLocation(data) {
    return request({
        url: '/api/wmsBinLocation/batchDelete',
        method: 'POST',
        data
    })
}

