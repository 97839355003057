import request from '@/api/request.js'


// 全量查询
export function listWmsCustomer(data) {
    return request({
        url: '/api/wmsCustomer/list',
        method: 'POST',
        data
    })
}