import request from '@/api/request.js'

/**
 * @Description: 单据
 */

// 分页查询
export function pageWmsInOutOrder(data) {
    return request({
        url: '/api/wmsInOutOrder/page',
        method: 'POST',
        data
    })
}

// 搜索过滤
export function listWmsInOutOrder(data) {
    return request({
        url: '/api/wmsInOutOrder/list',
        method: 'POST',
        data
    })
}

// 查看详情
export function detailWmsInOutOrder(data) {
    return request({
        url: '/api/wmsInOutOrder/detail',
        method: 'POST',
        data
    })
}

// 添加
export function addWmsInOutOrder(data) {
    return request({
        url: '/api/wmsInOutOrder/add',
        method: 'POST',
        data
    })
}

// 编辑
export function editWmsInOutOrder(data) {
    return request({
        url: '/api/wmsInOutOrder/edit',
        method: 'POST',
        data
    })
}

// 删除
export function delWmsInOutOrder(data) {
    return request({
        url: '/api/wmsInOutOrder/delete',
        method: 'POST',
        data
    })
}

// 批量删除
export function batchDeleteWmsInOutOrder(data) {
    return request({
        url: '/api/wmsInOutOrder/batchDelete',
        method: 'POST',
        data
    })
}

