import request from '@/api/request.js'


// 全量查询
export function listWmsSupplier(data) {
    return request({
        url: '/api/wmsSupplier/list',
        method: 'POST',
        data
    })
}

export function addWmsSupplier(data) {
    return request({
        url: '/api/wmsSupplier/add',
        method: 'POST',
        data
    })
}