import request from '@/api/request.js'

/**
 * @Description: 仓库
 */

// 分页查询
export function pageWmsWarehouse(data) {
    return request({
        url: '/api/wmsWarehouse/page',
        method: 'POST',
        data
    })
}

// 搜索过滤
export function listWmsWarehouse(data) {
    return request({
        url: '/api/wmsWarehouse/list',
        method: 'POST',
        data
    })
}

// 查看详情
export function detailWmsWarehouse(data) {
    return request({
        url: '/api/wmsWarehouse/detail',
        method: 'POST',
        data
    })
}

// 添加
export function addWmsWarehouse(data) {
    return request({
        url: '/api/wmsWarehouse/add',
        method: 'POST',
        data
    })
}

// 编辑
export function editWmsWarehouse(data) {
    return request({
        url: '/api/wmsWarehouse/edit',
        method: 'POST',
        data
    })
}

// 删除
export function delWmsWarehouse(data) {
    return request({
        url: '/api/wmsWarehouse/delete',
        method: 'POST',
        data
    })
}

// 批量删除
export function batchDeleteWmsWarehouse(data) {
    return request({
        url: '/api/wmsWarehouse/batchDelete',
        method: 'POST',
        data
    })
}

