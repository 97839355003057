import request from '@/api/request.js'

/**
 * @Description: 验货单
 */

// 分页查询
export function pageWmsInspectOrder(data) {
    return request({
        url: '/api/wmsInspectOrder/page',
        method: 'POST',
        data
    })
}

// 搜索过滤
export function listWmsInspectOrder(data) {
    return request({
        url: '/api/wmsInspectOrder/list',
        method: 'POST',
        data
    })
}

// 查看详情
export function detailWmsInspectOrder(data) {
    return request({
        url: '/api/wmsInspectOrder/detail',
        method: 'POST',
        data
    })
}

// 添加
export function addWmsInspectOrder(data) {
    return request({
        url: '/api/wmsInspectOrder/add',
        method: 'POST',
        data
    })
}

// 编辑
export function editWmsInspectOrder(data) {
    return request({
        url: '/api/wmsInspectOrder/edit',
        method: 'POST',
        data
    })
}

// 删除
export function delWmsInspectOrder(data) {
    return request({
        url: '/api/wmsInspectOrder/delete',
        method: 'POST',
        data
    })
}

// 批量删除
export function batchDeleteWmsInspectOrder(data) {
    return request({
        url: '/api/wmsInspectOrder/batchDelete',
        method: 'POST',
        data
    })
}

