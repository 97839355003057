import request from '@/api/request.js'

/**
 * @Description: 单位
 */

// 分页查询
export function pageWmsUnit(data) {
    return request({
        url: '/api/wmsUnit/page',
        method: 'POST',
        data
    })
}

// 搜索过滤
export function listWmsUnit(data) {
    return request({
        url: '/api/wmsUnit/list',
        method: 'POST',
        data
    })
}

// 查看详情
export function detailWmsUnit(data) {
    return request({
        url: '/api/wmsUnit/detail',
        method: 'POST',
        data
    })
}

// 添加
export function addWmsUnit(data) {
    return request({
        url: '/api/wmsUnit/add',
        method: 'POST',
        data
    })
}

// 编辑
export function editWmsUnit(data) {
    return request({
        url: '/api/wmsUnit/edit',
        method: 'POST',
        data
    })
}

// 删除
export function delWmsUnit(data) {
    return request({
        url: '/api/wmsUnit/delete',
        method: 'POST',
        data
    })
}

// 批量删除
export function batchDeleteWmsUnit(data) {
    return request({
        url: '/api/wmsUnit/batchDelete',
        method: 'POST',
        data
    })
}

